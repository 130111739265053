/* eslint-disable multiline-ternary */
import clsx from 'clsx';
import firebase from 'firebase/app';
import { geohashForLocation } from 'geofire-common';
import { FC, useCallback, useEffect, useState } from 'react';
import { useFirestore, useUser } from 'reactfire';

import { ActivePetDocument, ACTIVE_PETS_COLLECTION, UserPetDocument } from '../../types/firestore';

import Info from '../Info';

export interface MapOverlayPropTypes {
  readonly profile?: UserPetDocument;
  readonly activePet?: ActivePetDocument;
  readonly targetLocation: readonly [number, number];
  readonly onlyShowFavorites: boolean;
  readonly showBlocked: boolean;
  readonly showInvite: boolean;
  readonly toggleFavorites: () => void;
  readonly toggleBlocked: () => void;
  readonly toggleInvite: () => void;
}

const MapOverlay: FC<MapOverlayPropTypes> = ({
  profile,
  activePet,
  targetLocation,
  onlyShowFavorites,
  toggleFavorites,
  showBlocked,
  toggleBlocked,
  // showInvite,
  toggleInvite,
}) => {
  const { data: user } = useUser();
  const [showProfile, setShowProfile] = useState(false);

  const activePetRef = useFirestore().collection(ACTIVE_PETS_COLLECTION).doc(user.uid);

  const addActivePet = useCallback(() => {
    if (profile) {
      activePetRef.set({
        geohash: geohashForLocation([...targetLocation]),
        position: targetLocation,
        profile: {
          id: profile.id,
          icon: profile.icon,
          names: profile.names,
          sizes: profile.sizes,
        },
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
  }, [activePetRef, profile, targetLocation]);

  const removeActivePet = useCallback(() => {
    activePetRef.delete();
  }, [activePetRef]);

  useEffect(() => {
    if (activePet?.timestamp && Date.now() / 1000 - (activePet.timestamp.seconds ?? 0) > 90 * 60) {
      removeActivePet();
    }
  }, [activePet, removeActivePet]);

  return (
    <>
      {activePet ? (
        <button className="map-overlay-leave-park" onClick={removeActivePet}>
          Leave the Park!
        </button>
      ) : (
        <>
          <img
            alt="Paw Crosshair"
            className="fas fa-paw"
            src={`${process.env.PUBLIC_URL}/images/paw-icon.svg`}
          />
          <button className="map-overlay-go-park" onClick={addActivePet}>
            Go to the Park!
          </button>
        </>
      )}
      <button
        className="map-overlay-open-profile"
        style={{
          backgroundImage: `url(
            ${process.env.PUBLIC_URL}/images/dog-icons/${(profile && profile.icon) || '001'}.png)`,
        }}
        onClick={() => setShowProfile(true)}
      />

      <button
        className={clsx('map-overlay-toggle-favorites', { on: onlyShowFavorites })}
        onClick={toggleFavorites}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        </svg>
      </button>

      <button
        className={clsx('map-overlay-toggle-blocked', { on: showBlocked })}
        onClick={toggleBlocked}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </svg>
      </button>

      <button className={clsx('map-overlay-toggle-invite')} onClick={toggleInvite}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zM4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z" />
        </svg>
      </button>

      <Info
        open={showProfile || profile === undefined}
        initProfile={profile}
        onClose={() => setShowProfile(false)}
      />
    </>
  );
};

export default MapOverlay;
