import firebase from 'firebase/app';
import QRCode from 'qrcode';
import { FC, useEffect, useState } from 'react';

interface InvitePropTypes {
  readonly open: boolean;
  readonly user: firebase.User;
  readonly onClose: () => void;
}

const Invite: FC<InvitePropTypes> = ({ open, user, onClose }) => {
  const [ref, setRef] = useState<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (ref) {
      QRCode.toCanvas(ref, `https://bark-at-the-park.com#${user.uid}`, { width: 260 }).then(
        console.error,
      );
    }
  }, [ref, user.uid]);

  return open ? (
    <div className="backdrop">
      <div className="animate__animated animate__backInDown bark-popup">
        <div className="header">
          <h1>Invite a Friend</h1>
        </div>
        <canvas ref={setRef} />
        <div className="form">
          <button onClick={onClose} className="submit" style={{ width: '100%' }}>
            Close
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default Invite;
