/* eslint-disable multiline-ternary */
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import firebase from 'firebase/app';
import { FC, useCallback, useEffect, useState } from 'react';
import { useFirestore, useFirestoreDoc, useUser } from 'reactfire';

import {
  ACTIVE_PETS_COLLECTION,
  ActivePetDocument,
  USER_PETS_COLLECTION,
  UserPetDocument,
} from '../../types/firestore';

import './map.css';
import Invite from '../Invite';
import MapMarkers from './MapMarkers';
import MapOverlay from './MapOverlay';

const center: google.maps.LatLngLiteral = {
  lat: 33.813622,
  lng: -118.096187,
};

const Map: FC = () => {
  const { data: user } = useUser();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [mapCenter, setMapCenter] = useState(center);
  const [targetLocation, setTargetLocation] = useState<readonly [lat: number, lng: number]>([
    center.lat,
    center.lng,
  ] as const);
  const [onlyShowFavorites, setOnlyShowFavorites] = useState(false);
  const [showBlocked, setShowBlocked] = useState(false);
  const [showInvite, setShowInvite] = useState(false);

  const userPetsCollection = useFirestore().collection(USER_PETS_COLLECTION);
  const profileRef = userPetsCollection.doc(user.uid);
  const { status: profileStatus, data: profile } = useFirestoreDoc<UserPetDocument>(profileRef);

  const handleFavoriteUser = useCallback(
    (favoriteId: string) => {
      const p = profile?.data();
      if (p) {
        profileRef
          .update({ [`favorites.${favoriteId}`]: new Date().toISOString() })
          .catch(err => console.error('Failed to favorite user.', err));
      }
    },
    [profile, profileRef],
  );

  const handleUnfavoriteUser = useCallback(
    (favoriteId: string) => {
      const p = profile?.data();
      if (p) {
        profileRef
          .update({ [`favorites.${favoriteId}`]: firebase.firestore.FieldValue.delete() })
          .catch(err => console.error('Failed to unfavorite user.', err));
      }
    },
    [profile, profileRef],
  );

  const handleBlockUser = useCallback(
    (blockId: string) => {
      const p = profile?.data();
      if (p) {
        profileRef
          .update({ [`blockedUsers.${blockId}`]: 1 })
          .then(() => userPetsCollection.doc(blockId).update({ [`blockedUsers.${p.id}`]: 2 }))
          .catch(err => console.error('Failed to completely block user.', err));
      }
    },
    [profile, profileRef, userPetsCollection],
  );

  const handleUnblockUser = useCallback(
    (blockId: string) => {
      const p = profile?.data();
      if (p && p.blockedUsers[blockId] === 1) {
        profileRef
          .update({ [`blockedUsers.${blockId}`]: firebase.firestore.FieldValue.delete() })
          .then(() =>
            userPetsCollection
              .doc(blockId)
              .update({ [`blockedUsers.${p.id}`]: firebase.firestore.FieldValue.delete() }),
          )
          .catch(err => console.error('Failed to completely unblock user.', err));
      }
    },
    [profile, profileRef, userPetsCollection],
  );

  const activePetRef = useFirestore().collection(ACTIVE_PETS_COLLECTION).doc(user.uid);
  const { status: activePetStatus, data: activePet } =
    useFirestoreDoc<ActivePetDocument>(activePetRef);

  useEffect(() => {
    const pos = activePet?.data()?.position;
    if (pos) {
      setMapCenter({ lat: pos[0], lng: pos[1] });
    }
  }, [activePet]);

  useEffect(() => {
    !activePet?.data() &&
      navigator.geolocation &&
      navigator.geolocation.getCurrentPosition(function (position) {
        setMapCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setTargetLocation([position.coords.latitude, position.coords.longitude]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(() => {
    if (map) {
      setTargetLocation([map.getCenter()!.lat(), map.getCenter()!.lng()]);
    }
  }, [map]);

  return profileStatus === 'success' && activePetStatus === 'success' ? (
    <>
      <LoadScript googleMapsApiKey={String(process.env.REACT_APP_GOOGLE_MAPS_KEY)}>
        <GoogleMap
          mapContainerClassName="map-container"
          center={mapCenter}
          zoom={16}
          options={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            zoomControl: false,
            gestureHandling: 'greedy',
            minZoom: 13,
          }}
          onLoad={setMap}
          onIdle={handleChange}
        >
          <MapMarkers
            profile={profile?.data()}
            targetLocation={targetLocation}
            activePet={activePet?.data()}
            onlyShowFavorites={onlyShowFavorites}
            showBlocked={showBlocked}
            onFavorite={handleFavoriteUser}
            onUnfavorite={handleUnfavoriteUser}
            onBlock={handleBlockUser}
            onUnblock={handleUnblockUser}
          />
        </GoogleMap>
      </LoadScript>
      <MapOverlay
        profile={profile?.data()}
        activePet={activePet?.data()}
        targetLocation={targetLocation}
        onlyShowFavorites={onlyShowFavorites}
        toggleFavorites={() => setOnlyShowFavorites(v => !v)}
        showBlocked={showBlocked}
        toggleBlocked={() => setShowBlocked(v => !v)}
        showInvite={showInvite}
        toggleInvite={() => setShowInvite(true)}
      />
      <Invite open={showInvite} user={user} onClose={() => setShowInvite(false)} />
    </>
  ) : null;
};

export default Map;
