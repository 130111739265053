import { InfoWindow, Marker } from '@react-google-maps/api';
import { formatDistance } from 'date-fns';
import firebase from 'firebase/app';
import { geohashQueryBounds } from 'geofire-common';
import { debounce } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useFirestore } from 'reactfire';

import { ActivePetDocument, ACTIVE_PETS_COLLECTION, UserPetDocument } from '../../types/firestore';

export interface MapMarkerProps {
  readonly profile?: UserPetDocument;
  readonly targetLocation: readonly [lat: number, lng: number];
  readonly activePet?: ActivePetDocument;
  readonly onlyShowFavorites: boolean;
  readonly showBlocked: boolean;
  readonly onFavorite: (userId: string) => void;
  readonly onUnfavorite: (userId: string) => void;
  readonly onBlock: (userId: string) => void;
  readonly onUnblock: (userId: string) => void;
}

const MapMarkers: FC<MapMarkerProps> = ({
  profile,
  targetLocation,
  activePet,
  onlyShowFavorites,
  showBlocked,
  onFavorite,
  onUnfavorite,
  onBlock,
  onUnblock,
}) => {
  const db = useFirestore();

  const [activePets, setActivePets] = useState<ReadonlyArray<ActivePetDocument>>([]);
  const [info, setInfo] = useState<ActivePetDocument>();
  const [fromDate, setFromDate] = useState(() =>
    firebase.firestore.Timestamp.fromDate(new Date(Date.now() - 90 * 60 * 1000)),
  );

  /**
   * Every minute, set a new fromDate of 90 minutes ago.
   */
  useEffect(() => {
    const ref = setInterval(
      () =>
        setFromDate(firebase.firestore.Timestamp.fromDate(new Date(Date.now() - 90 * 60 * 1000))),
      60_000 * 10,
    );
    return () => clearInterval(ref);
  }, []);

  /**
   * Query active pets with geohash and timestamp > fromDate.
   */
  useEffect(() => {
    var unsubscribe = db
      .collection(ACTIVE_PETS_COLLECTION)
      .orderBy('timestamp', 'desc')
      .where('timestamp', '>', fromDate)
      .limit(1)
      .onSnapshot(
        debounce(() => {
          Promise.all(
            geohashQueryBounds([...targetLocation], 2_000).map(b =>
              db
                .collection(ACTIVE_PETS_COLLECTION)
                .orderBy('geohash')
                .startAt(b[0])
                .endAt(b[1])
                .get(),
            ),
          )
            .then(snapshots => snapshots.flatMap(snap => snap.docs))
            .then<ActivePetDocument[], never>(
              matchingDocs => Promise.all(matchingDocs.map(doc => doc.data())) as any,
            )
            .then(pets =>
              setActivePets(
                pets.filter(pet => pet.timestamp.seconds > Date.now() / 1000 - 90 * 60),
              ),
            );
        }, 500),
      );
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, fromDate, Math.round(targetLocation[0] * 1000), Math.round(targetLocation[1] * 1000)]);

  const filteredActivePets = useMemo(() => {
    const favoritesFilter = (pet: ActivePetDocument) =>
      onlyShowFavorites && activePet?.profile.id !== pet.profile.id
        ? !!profile?.favorites[pet.profile.id]
        : true;

    /**
     * Filters out blocked users, unless showBlockedUsers.
     * If showBlockedUsers, only show "blocked" and not "blockers".
     */
    const blockedFilter = (pet: ActivePetDocument) =>
      showBlocked
        ? profile?.blockedUsers[pet.profile.id] !== 2
        : !profile?.blockedUsers[pet.profile.id];

    return activePets.filter(pet => favoritesFilter(pet) && blockedFilter(pet));
  }, [
    activePet?.profile.id,
    activePets,
    onlyShowFavorites,
    profile?.blockedUsers,
    profile?.favorites,
    showBlocked,
  ]);

  useEffect(() => {
    if (info && !filteredActivePets.find(p => p.profile.id === info.profile.id)) {
      setInfo(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredActivePets]);

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    if (hash && hash !== profile?.id && filteredActivePets.some(a => a.profile.id === hash)) {
      onFavorite(hash);
      window.history.pushState(
        '',
        document.title,
        window.location.pathname + window.location.search,
      );
    }
  }, [filteredActivePets, onFavorite, profile?.id]);

  return (
    <>
      {info ? (
        <InfoWindow
          options={{
            pixelOffset: {
              width: 0,
              height: -60,
              equals: () => false,
            },
          }}
          position={{
            lat: info.position[0],
            lng: info.position[1],
          }}
          onCloseClick={() => setInfo(undefined)}
        >
          <div style={{ minWidth: 200 }}>
            <strong style={{ fontSize: '16px', lineHeight: '18px' }}>
              {info.profile.names}
              {info.profile.id === profile?.id ? <small> (me)</small> : null}
            </strong>
            <br />
            Size: {info.profile.sizes}
            <br />
            Gone to the park{' '}
            {formatDistance(info.timestamp.seconds * 1000, Date.now(), {
              addSuffix: true,
            })}
            .
            {info.profile.id !== profile?.id ? (
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                {profile?.favorites[info.profile.id] ? (
                  <button onClick={() => onUnfavorite(info.profile.id)}>Remove Favorite</button>
                ) : (
                  <button onClick={() => onFavorite(info.profile.id)}>Add Favorite</button>
                )}
                {profile?.blockedUsers[info.profile.id] === 1 ? (
                  <button onClick={() => onUnblock(info.profile.id)}>Unblock</button>
                ) : (
                  <button onClick={() => onBlock(info.profile.id)}>Block</button>
                )}
              </div>
            ) : null}
          </div>
        </InfoWindow>
      ) : null}

      {filteredActivePets.map(pet => (
        <Marker
          key={pet.profile.id}
          animation={google.maps.Animation.DROP}
          title={pet.profile.names}
          icon={{
            url: `${process.env.PUBLIC_URL}/images/dog-icon-markers/marker_${pet.profile.icon}.png`,
            scaledSize:
              activePet?.profile.id === pet.profile.id
                ? { width: 80, height: 80, equals: () => false }
                : { width: 70, height: 70, equals: () => false },
          }}
          position={{
            lat: pet.position[0],
            lng: pet.position[1],
          }}
          onClick={() => setInfo(pet)}
        />
      ))}
    </>
  );
};

export default MapMarkers;
