import 'firebase/auth';
import 'firebase/firestore';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import 'animate.css';
import './index.css';

import App from './App';

window.addEventListener('DOMContentLoaded', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
