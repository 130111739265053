import firebase from 'firebase/app';

export type DocSnap<T> = firebase.firestore.DocumentSnapshot<T>;

export const USER_PETS_COLLECTION = 'user_pets';
export const ACTIVE_PETS_COLLECTION = 'active_pets';

export interface UserPetDocument {
  readonly id: string;
  readonly icon: string;
  readonly names: string;
  readonly sizes: string;
  // { [userId]: ISO Date }
  readonly favorites: Readonly<Record<string, string>>;
  // { [userId]: 1 = blocked | 2 = blocker }
  readonly blockedUsers: Readonly<Record<string, 1 | 2>>;
}

export interface ActivePetDocument {
  readonly geohash: string;
  readonly position: readonly [lat: number, lng: number];
  readonly profile: Pick<UserPetDocument, 'id' | 'icon' | 'names' | 'sizes'>;
  readonly timestamp: { readonly seconds: number };
}
