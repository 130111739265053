import { FC } from 'react';
import { FirebaseAppProvider, useSigninCheck } from 'reactfire';

import Home from './components/Home';
import Map from './components/Map';

import { firebaseConfig } from './firebaseConfig';

const LoginRouter: FC = () => {
  const { status, data: signInCheckResult } = useSigninCheck();
  return status === 'success' && signInCheckResult.signedIn ? <Map /> : <Home />;
};

const App: FC = () => (
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <LoginRouter />
  </FirebaseAppProvider>
);

export default App;
