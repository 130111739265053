import firebase from 'firebase/app';
import { FC, useCallback, useEffect, useState } from 'react';

import './home.css';

const Home: FC = () => {
  const [show, setShow] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    const handle = setTimeout(() => setShow(true), 1000);
    return () => clearTimeout(handle);
  }, []);

  const handleSignIn = useCallback(() => {
    setIsLoggingIn(true);
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .catch(() => setIsLoggingIn(false));
  }, []);

  return show ? (
    <div className="container">
      <div className="home-page">
        <div className="blue-box"></div>
        <div className="green-box"></div>
        <h1>Bark at the Park</h1>
        <p>A place for your furry friend to make their own furry friend.</p>
        <div className="sign-in-button animate__bounceIn">
          <img
            alt="Google Paw"
            id="google-paw"
            src={`${process.env.PUBLIC_URL}/images/google-paw.png`}
          />
          <button className="google-sign-in" disabled={isLoggingIn} onClick={handleSignIn}>
            Sign in with Google
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default Home;
