import { FC } from 'react';
import Select from 'react-select';

const OPTIONS: ReadonlyArray<Readonly<{ value: string; label: any }>> = [
  '001',
  '002',
  '003',
  '004',
  '005',
  '006',
  '007',
  '008',
  '009',
  '010',
  '011',
  '012',
  '013',
  '014',
  '015',
  '016',
  '017',
  '018',
  '019',
  '020',
  '021',
  '022',
  '023',
].map(id => ({
  value: id,
  label: (
    <img
      alt=""
      className="dog-image-profile-selected"
      src={`${process.env.PUBLIC_URL}/images/dog-icons/${id}.png`}
    />
  ),
}));

interface DogIconPickerPropTypes {
  readonly open?: boolean;
  readonly onChange: Select['onChange'];
}

const DogIconPicker: FC<DogIconPickerPropTypes> = ({ open = false, onChange }) =>
  open ? (
    <div className="dog-icon-picker-select-button">
      <Select menuIsOpen maxMenuHeight={440} onChange={onChange} options={OPTIONS} />
    </div>
  ) : null;

export default DogIconPicker;
