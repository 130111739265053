/* eslint-disable multiline-ternary */
import Filter from 'bad-words';
import clsx from 'clsx';
import firebase from 'firebase/app';
import { FC, useCallback, useState } from 'react';
import { useFirestore, useUser } from 'reactfire';

import { UserPetDocument, USER_PETS_COLLECTION } from '../../types/firestore';

import DogIconPicker from './DogIconPicker';
import './info.css';

export interface InfoPropTypes {
  readonly open?: boolean;
  readonly initProfile?: UserPetDocument;
  readonly onClose: () => void;
}

interface DumbInfoPropTypes {
  readonly user: firebase.User;
  readonly initProfile?: UserPetDocument;
  readonly onSave: (profile: UserPetDocument) => void;
  readonly onClose: () => void;
  readonly onSignOut: () => void;
}

const badWords = new Filter();

const Info: FC<DumbInfoPropTypes> = ({ user, initProfile, onSave, onClose, onSignOut }) => {
  const [profile, setProfile] = useState<UserPetDocument>(
    initProfile || {
      id: user.uid,
      icon: '001',
      names: '',
      sizes: '',
      favorites: {},
      blockedUsers: {},
    },
  );

  const [showDogPicker, setShowDogPicker] = useState(false);

  const isNamesInvalid = !profile.names || badWords.isProfane(profile.names);
  const isSizesInvalid = !profile.sizes;
  const isInvalid = isNamesInvalid || isSizesInvalid;

  return (
    <div className="backdrop">
      <div className="animate__animated animate__backInDown bark-popup">
        <div className="header">
          <h1>Bark Profile</h1>
        </div>
        <div
          className="image"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/dog-icons/${profile.icon}.png)`,
          }}
        >
          <img
            alt="Open Dog Icon Selector"
            onClick={() => setShowDogPicker(true)}
            src={`${process.env.PUBLIC_URL}/images/add.svg`}
          />
        </div>
        <div className="form">
          <h4>Names (of one or more dogs)</h4>
          <input
            type="text"
            className={clsx('text', {
              error: isNamesInvalid,
            })}
            value={profile.names}
            onChange={event =>
              setProfile(state => ({
                ...state,
                names: event.target.value.slice(0, 30),
              }))
            }
          />
          <div className={clsx('error-text', { show: isNamesInvalid })}>
            This name is not valid.
          </div>

          <h4>Size (of largest dog)</h4>
          <select
            name="size"
            id="pet-size"
            className={clsx({ error: isSizesInvalid })}
            value={profile.sizes}
            onChange={event => setProfile(state => ({ ...state, sizes: event.target.value }))}
          >
            <option value="">Choose an Option</option>
            <option value="toy">Toy</option>
            <option value="small">Small</option>
            <option value="medium">Medium</option>
            <option value="large">Large</option>
          </select>
          <div className={clsx('error-text', { show: isSizesInvalid })}>Size is required.</div>

          <div className="buttons">
            <button className="submit" disabled={isInvalid} onClick={() => onSave(profile)}>
              Submit
            </button>
            <button onClick={onClose} className="cancel">
              Cancel
            </button>
          </div>
        </div>
        <DogIconPicker
          open={showDogPicker}
          onChange={event => {
            if (event) {
              setProfile(state => ({ ...state, icon: event.value }));
            }
            setShowDogPicker(false);
          }}
        />
      </div>
      <div className="sign-out-container">
        <button onClick={onSignOut}>Sign Out</button>
      </div>
    </div>
  );
};

const InfoWithFirestoreMutation: FC<InfoPropTypes> = ({ open, initProfile, onClose }) => {
  const { data: user } = useUser();

  const profileRef = useFirestore().collection(USER_PETS_COLLECTION).doc(user.uid);

  const addProfile = useCallback(
    (profile: UserPetDocument) => {
      profileRef.set(profile).catch(err => console.error(err));
      onClose();
    },
    [onClose, profileRef],
  );

  const handleSignOut = useCallback(() => {
    firebase
      .auth()
      .signOut()
      // TODO: This is a hack to avoid an error.
      .then(() => window.location.reload())
      .catch(err => console.error(err));
  }, []);

  return open ? (
    <Info
      user={user}
      initProfile={initProfile}
      onSave={addProfile}
      onClose={onClose}
      onSignOut={handleSignOut}
    />
  ) : null;
};

export default InfoWithFirestoreMutation;
